html {
  overflow: hidden;
  overflow-y: scroll;
}

body, html {
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
}

#App {
  display: grid;
  grid-template-rows: 120px 1fr;
}

#header {
  display: flex;
  height: 100%;
  align-items: center;
  padding-left: 100px;
  background: linear-gradient(12deg, hsl(220, 100%, 80%), hsl(270, 100%, 80%));
}
#header img {
  height: 80%;
  border-radius: 10px;
}
#header #navbar {
  padding-left: 10px;
  width: 80%;
  display: flex;
  justify-content: space-around;
}
#header #navbar a {
  text-decoration: none;
  color: black;
  font-size: 30px;
}

#homePage {
  padding-left: 10px;
  margin-right: 20px;
  margin-bottom: 40%;
}

#aboutPage {
  padding-left: 10px;
  margin-right: 20px;
  margin-bottom: 40%;
}

#challengePage {
  padding-left: 10px;
  margin-right: 20px;
  margin-bottom: 40%;
}
#challengePage .timeFrameElement {
  border: 1px solid black;
  border-radius: 10px;
  padding: 0 0 10px 10px;
  cursor: pointer;
}
#challengePage .timeFrameElement .challengeElement {
  cursor: default;
  margin-bottom: 10px;
}

#projectPage {
  padding-left: 10px;
  margin-right: 20px;
  margin-bottom: 40%;
}
#projectPage #project {
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px 10px 0 10px;
  margin-bottom: 5px;
}
#projectPage #project h2 {
  margin-top: 3px;
}
#projectPage #project a {
  border: 2px solid gray;
  border-radius: 10px;
  color: black;
  text-decoration: none;
  background-color: lightgray;
  padding: 3px;
  cursor: pointer;
  margin-right: 10px;
}

#wikiPage {
  padding-left: 10px;
  margin-right: 20px;
  margin-bottom: 40%;
}
#wikiPage .returnLink {
  background-color: black;
  padding: 10px;
  border-radius: 5px;
  color: white;
  text-decoration: none;
}
#wikiPage .wikiLinks a {
  margin-right: 10px;
  font-size: 40px;
}

.category-page {
  padding-left: 10px;
  margin-right: 20px;
  margin-bottom: 40%;
  margin-top: 30px;
}
.category-page .returnLink {
  background-color: black;
  padding: 10px;
  border-radius: 5px;
  color: white;
  text-decoration: none;
}
.category-page .categoryLinks {
  display: flex;
  flex-wrap: wrap;
}
.category-page .categoryLinks .categoryLink {
  background-color: black;
  padding: 10px;
  margin-right: 10px;
  border-radius: 10px;
  font-size: large;
  color: white;
  text-decoration: none;
}
.category-page .categoryLinks .styleGuide {
  background-color: rgb(186, 0, 0);
}
.category-page .categoryLinks .library {
  background-color: rgb(0, 0, 188);
}

.topic-page {
  padding-left: 10px;
  margin-right: 20px;
  margin-bottom: 40%;
  margin-top: 10px;
}
.topic-page .code-interface {
  align-self: center;
  background-color: rgb(206, 206, 206);
  border: 2px solid black;
  border-radius: 20px;
  padding: 0 10px;
  width: fit-content;
}
.topic-page .returnLink {
  background-color: black;
  padding: 10px;
  border-radius: 5px;
  color: white;
  text-decoration: none;
}