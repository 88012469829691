@use "Library";

@mixin import {
  #wikiPage {
    @include Library.mainPageFormat();
    @include returnLink();
    .wikiLinks {
      a {
        margin-right: 10px;
        font-size: 40px;
      }
    }
  }

  .category-page {
    @include Library.mainPageFormat();
    @include returnLink();
    margin-top: 30px;

    .categoryLinks {
      display: flex;
      flex-wrap: wrap;

      .categoryLink {
        background-color: black;
        padding: 10px;
        margin-right: 10px;
        border-radius: 10px;
        font-size: large;
        color: white;
        text-decoration: none;
      }
      .styleGuide {background-color: rgb(186, 0, 0);}
      .library {background-color: rgb(0, 0, 188);}
    }
  }

  .topic-page {
    @include Library.mainPageFormat();
    margin-top: 10px;

    .code-interface {
      align-self: center;
      background-color: rgb(206, 206, 206);
      border: 2px solid black;
      border-radius: 20px;
      padding: 0 10px;
      width: fit-content;
    }

    @include returnLink();
  }
}

@mixin returnLink {
  .returnLink {
    background-color: black;
    padding: 10px;
    border-radius: 5px;
    color: white;
    text-decoration: none;
  }
}