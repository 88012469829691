@use "Library";

@mixin import {
  #projectPage {
    @include Library.mainPageFormat();
    #project {
      border: 1px solid black;
      border-radius: 5px;
      padding: 10px 10px 0 10px;
      margin-bottom: 5px;
      h2 {
        margin-top: 3px;
      }
      a {
        border: 2px solid gray;
        border-radius: 10px;
        color: black;
        text-decoration: none;
        background-color: lightgray;
        padding: 3px;
        cursor: pointer;
        margin-right: 10px;
      }
    }
  }
}