@use "Library"; @use "Home"; @use "Challenges"; @use "Projects";
@use "Wiki"; @use "About";


html {
  overflow: hidden;
  overflow-y: scroll;
}
body, html {
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
}

#App {
  display: grid;
  grid-template-rows: 120px 1fr;
}

#header {
  display: flex;
  height: 100%;
  align-items: center;
  padding-left: 100px;
  background: linear-gradient(12deg, Library.$secondary-color-800, Library.$primary-color-800);
  img {
    height: 80%;
    border-radius: 10px;
  }
  #navbar {
    padding-left: 10px;
    width: 80%;
    display: flex;
    justify-content: space-around;
    a {
      text-decoration: none;
      color: black;
      font-size: 30px;
    }
  }
}

@include Home.import();
@include About.import();
@include Challenges.import();
@include Projects.import();
@include Wiki.import();

